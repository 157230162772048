define(['app', '$window', 'accessibleModalHelper', 'dateSelector'], function (app, $window, accessibleModalHelper, dateSelector)  {

  const ageGateModal = () => {

    const component = {};

    const _config = {
      selectors: {
        closeButton: '[data-close]',
        rememberMeCheckbox: 'input[name="remember-me"]',
        submitButton: '[data-submit-button]',
        daySelect: '[data-input-day]',
        siteContent: '#site-content',
        modalContainer: '.ageGateModal_innerContainer',
        logoContainer: '.ageGateModal_logoContainer',
        dateSelectorContainer: '.ageGateModal_dateSelectorContainer',
        mainContents: '[data-main-contents]'
      },

      settings: {
        cookieName: 'ageVerified',
        redirectURL: siteObj.siteURL,
        rememberMe: true,
        isSiteWide: false,
        ageVerified: false,
      },

      attributes: {
        age: 'data-min-age-years',
        siteWide: 'data-site-wide'
      },

      props: {
        siteWideNotVerifiedMessage: siteObj.props.ageGate.siteWideNotVerifiedMessage
      }
    };

    const _init = (element) => {
      component.element = element;
      component.config.settings.minAgeYears = parseInt(component.element.getAttribute(component.config.attributes.age));
      component.siteWideCheck();
      component.getQueryParams();
      component.checkShowcase();

      if(component.config.settings.ageVerified === true && component.getCookieValue() === false) {
        component.config.settings.rememberMe = false;
        component.createCookie(component.config.settings.minAgeYears);
      }

      if (component.config.settings.minAgeYears > 0 && component.getCookieValue() === false) {
        component.loadDateSelector();
        component.showModal();
        component.getDateToday();
        component.attachListeners();
      }
    };

    const _setupAccessibleModal = () => {
      const closeHandler = component.config.settings.isSiteWide ? () => {} : component.redirectNoAgeProvided;
      const startElement = component.element.querySelector(component.config.selectors.modalContainer);
      component.modalHelper = new accessibleModalHelper(component.element, closeHandler, startElement);
    };

    const _showModal = () => {
      component.setupAccessibleModal();
      component.element.classList.toggle('show');
      document.querySelector(component.config.selectors.siteContent).setAttribute('aria-hidden', 'true');
    };

    const _siteWideCheck = () => {
      if (component.element.hasAttribute(component.config.attributes.siteWide)) {
        component.config.settings.isSiteWide = true;
      }
    };

    const _checkShowcase = () => {

      if (component.element.parentNode.className == "globalComponentsShowcase") {
        component.element.outerHTML = component.element.innerHTML;
        document.querySelector(component.config.selectors.modalContainer).style.margin = "auto";
        document.querySelector(component.config.selectors.modalContainer).style.border = "1px solid black";
      }
    };

    const _getDateToday = () => {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();

      component.getDateToday.presentDay = day;
      component.getDateToday.presentMonth = month;
      component.getDateToday.presentYear = year;
    };

    const _createCookie = (ageOverride) => {
      const age = ageOverride ? ageOverride : component.getAge();
      if (component.config.settings.rememberMe) {
        document.cookie = `${component.config.settings.cookieName}=${component.isAgeVerified(age)}; max-age=31536000; path=/`;
      } else {
        document.cookie = `${component.config.settings.cookieName}=${component.isAgeVerified(age)}; path=/`;
      }
    };

    const _getCookieValue = () => {
      return document.cookie.includes(component.config.settings.cookieName) && document.cookie.split('; ').find(cookie => cookie.startsWith('ageVerified')).split('=')[1] === "true";
    };

    const _createCookieListener = () => {
      let element = component.element.querySelector(component.config.selectors.rememberMeCheckbox);
      element.addEventListener('change', () => {
        component.config.settings.rememberMe = !component.config.settings.rememberMe;
      })
    };

    const _closeButtonListener = () => {
      let element = component.element.querySelector(component.config.selectors.closeButton);

      element.addEventListener('click', () => {
        component.redirectNoAgeProvided();
      });
    };

    const _redirectNoAgeProvided = () => {
      $window.location = `${component.config.settings.redirectURL.toString()}?ageProvided=false`;
    };

    const _redirectAgeNotVerified = () => {
      $window.location = `${component.config.settings.redirectURL.toString()}?ageVerified=false`;
    };

    const _submitButtonListener = () => {
      const element = component.element.querySelector(component.config.selectors.submitButton);
      element.addEventListener('click', () => {
          component.createCookie();
        const siteContent = document.querySelector(component.config.selectors.siteContent);
        if (component.dateSelector.allDatesSelectedAndValid() && component.isAgeVerified(component.getAge())) {
          component.element.classList.remove('show');
          siteContent.setAttribute('aria-hidden', 'false');
        } else if (!component.dateSelector.allDatesSelectedAndValid()) {
          component.dateSelector.showErrorMessage();
        } else {
          component.handleUserNotVerified();
        }
        })
    };

    const _handleUserNotVerified = () => {
      if (component.config.settings.isSiteWide) {
        const mainContents = component.element.querySelector(component.config.selectors.mainContents);
        const notVerifiedMsg = component.config.props.siteWideNotVerifiedMessage;
        const notVerifiedEl = document.createElement('h2');

        mainContents.innerHTML = '';
        notVerifiedEl.classList.add('ageGateModal_userNotVerified');
        notVerifiedEl.id = 'age-gate-modal-not-verified-title';
        notVerifiedEl.innerHTML = notVerifiedMsg;
        mainContents.appendChild(notVerifiedEl);
        component.element.setAttribute('aria-labelledby', 'age-gate-modal-not-verified-title');
      } else {
        component.redirectAgeNotVerified();
      }
    };

    const _getDOB = () => {
      let day = component.dateSelector.selectedDay;
      let month = component.dateSelector.selectedMonth;
      let year = component.dateSelector.selectedYear;

      component.getDOB.day = parseInt(day);
      component.getDOB.month = parseInt(month);
      component.getDOB.year = parseInt(year);
    };

    const _getAge = () => {
      component.getDOB();
      let age = component.getDateToday.presentYear - component.getDOB.year;
      if (component.getDateToday.presentMonth < component.getDOB.month || (component.getDateToday.presentMonth === component.getDOB.month && component.getDateToday.presentDay < component.getDOB.day)) {
        age--;
      }
      return age;
    };

    const _isAgeVerified = (age) => {
      return age >= component.config.settings.minAgeYears;
    };

    const _loadDateSelector = () => {
      component.dateSelector = dateSelector();
      let dateSelectorElement = component.element.querySelector(component.config.selectors.dateSelectorContainer);
      component.dateSelector.init(dateSelectorElement);
    };

    const _attachListeners = () => {
      if (!component.config.settings.isSiteWide) {
        component.closeButtonListener();
      }

      component.createCookieListener();
      component.submitButtonListener();
    };

    const _getQueryParams = () => {
      const paramValue = new URLSearchParams($window.location.search).get('aVer');
      component.config.settings.ageVerified = Number(paramValue) === 1 ? true : false;
    };

    component.init = _init;
    component.config = _config;
    component.showModal = _showModal;
    component.setupAccessibleModal = _setupAccessibleModal;
    component.getDateToday = _getDateToday;
    component.createCookieListener = _createCookieListener;
    component.createCookie = _createCookie;
    component.submitButtonListener = _submitButtonListener;
    component.closeButtonListener = _closeButtonListener;
    component.attachListeners = _attachListeners;
    component.loadDateSelector = _loadDateSelector;
    component.redirectAgeNotVerified = _redirectAgeNotVerified;
    component.redirectNoAgeProvided = _redirectNoAgeProvided;
    component.getAge = _getAge;
    component.isAgeVerified = _isAgeVerified;
    component.getDOB = _getDOB;
    component.getCookieValue = _getCookieValue;
    component.siteWideCheck = _siteWideCheck;
    component.checkShowcase = _checkShowcase;
    component.handleUserNotVerified = _handleUserNotVerified;
    component.getQueryParams = _getQueryParams;

    return component;
  };

  return ageGateModal;
});
